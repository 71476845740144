.email-step {
    margin-top: 10%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.email-step h3 {
    color: grey;
}

form {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

input {
    display: flex;
    width: 100%;
    border: 3px solid var(--main-color);
    border-radius: 10px;
    background-color: white;
    margin-bottom: 5%;
    padding: 15px 0;
    text-indent: 20px;
    font-size: large;
}

input::placeholder {
    padding-left: 10px;
}

.check-wrapper {
    margin-top: 5%;
}

.checks {
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: row;
    text-align: left;
    margin-bottom: 5%;

    .checkboxes {
        position: absolute;
        width: 20px;
        height: 20px;
    }

    label {
        margin-left: 50px;
        font-size: 14px;
    }
}

.submit-button {
    margin-top: 5%;
    margin-bottom: 10%;
    appearance: none;
    width: 80%;
    font-size: large;
    font-weight: 900;
    padding: 15px;
    border: none;
    border-radius: 10px;
    text-decoration: none;
    background-color: var(--main-color);
    color: white;
    cursor: pointer;
}