.Footer {
    width: 100%;
    display: flex;
    background-color: var(--main-color);
    height: 60px;
    margin-top: 50px;
  }
  
  .Footer ul {
    color: white;
    list-style-type: none;
    display: flex;
    padding: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  
  .Footer ul li {
    font-size: 14px;
    min-width: 33.33%;
    max-width: 33.33%;
    overflow-wrap: break-word;
  }

  p {
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    font-weight: bold;
    line-height: 1.5;
  }
  
@media (hover: hover) {
  .Footer ul li {
    transition: font-weight 0.3s ease;
  }
  
  .Footer ul li:hover {
    cursor: pointer;
    font-weight: 900;
  }
}
  