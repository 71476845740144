.Thx {
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.thx-title {
    margin-top: 10%;
    text-align: center;
    line-height: 50px;
}

.thx-text {
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.25;
}

@media only screen and (min-width: 1024px) {
    .Thx h2 {
        scale: 1.5;
    }
}

@media only screen and (min-width: 1900px) {
    .Thx h2 {
        scale: 2;
    }
}

.Thx .Link {
    text-decoration: none;
}