.verify {
    text-align: center;
    font-size: large;

    margin-top: 5vh;
    height: 95vh;
    display: flex;
    flex-direction: column;

    .wrapper-verify {
        flex: 1;
        margin: 0 10%;
    }

    h1 {
        margin-top: 50px;
        font-size: larger;
    }
}