.Survey {
    background-color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
    margin: auto;
    align-items: center;
}

@media only screen and (min-width: 1024px) {
    .content {
        max-width: 50%;
    }

    .survey-step h2 {
        scale: 1.1;
    }
}

@media only screen and (min-width: 1900px) {
    .content {
        max-width: 33%;
    }
}

.fade-enter {
    opacity: 0;
    transform: scale(0.9);
}
  
.fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 750ms ease-in, transform 450ms ease-in;
}
  
.fade-exit {
    display: none;
}

.content {
    flex: 1;
    width: 100%;
    height: 100%;
}

.imageWrapper-survey {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 75px;
    background: white;
}

.App-logo {
    padding: 0;
    margin: 0;
    padding-top: 5px;
    height: 75px;
    width: auto;
    scale: 2.5;
  }

.survey-step {
    max-height: 300px;
    margin-left: 10%;
    margin-right: 10%;
    padding-left: 5%;
    padding-right: 5%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.survey-step h2 {
    font-weight: 500;
}