.feedback {
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.feedback textarea {
    margin-bottom: 20px;
    font-size: large;
    font-family: sans-serif;
    padding: 10px;
    resize: none;
    width: 80%;
}

.feedback button {
    appearance: none;
    width: 80%;
    font-size: large;
    font-weight: 900;
    padding: 15px;
    border: none;
    border-radius: 10px;
    text-decoration: none;
    background-color: var(--main-color);
    color: white;
    cursor: pointer;
}