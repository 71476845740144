/*.ProgressBar {
    margin-top: 10%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: var(--main-color);
    height: 5px;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.progress-point {
    height: 2dvh;
    border-radius: 30px;
    width: 2dvh;
    background-color: white;
    border: 3px solid var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.progress-point.done {
    background-color: var(--main-color);
    color: white;
}*/

.progress-text {
    margin-top: 10%;
}