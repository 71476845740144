.App {
  text-align: center;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
}

.start-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.startText {
  margin-left: 10%;
  margin-right: 10%;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 100;
}

small {
  margin-top: 2.5%;
  font-size: 12px;
}

.link {
  cursor: pointer;
  font-size: 12px;
}

.imageWrapper-start {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75px;
  background: var(--main-color);
}

.App-logo {
  display: flex;
  width: 300px;
  padding-top: 30px;
}

.start-button {
  font-size: 24px;
  font-weight: 900;
  padding: 20px 75px;
  margin-top: 5%;
  background-color: var(--main-color);
  border-radius: 30px;
  border: none;
  text-decoration: none;
  color: white;
}

.start-button:hover {
  box-shadow: 0px 0px 15px var(--main-color);
  cursor: pointer;
}

.start-button .link {
  text-decoration: none;
}